import React, { useState } from 'react'
import { Logo } from '../Assets'

export default function Nav() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <main class="px-2 py-6 mx-auto lg:py-8 sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-5 lg:px-8">
      <div class="relative flex items-center justify-between lg:justify-center lg:space-x-40">
        <ul class="flex items-center hidden space-x-14 lg:flex">
          <li>
            <a
              href="/"
              aria-label="Home"
              class="tracking-wide text-black transition-colors duration-200 hover:underline uppercase font-bold"
            >
              Home
            </a>
          </li>
          <li>
            <a
              href="/about"
              aria-label="About"
              class="tracking-wide text-black transition-colors duration-200 hover:underline uppercase font-bold"
            >
              About
            </a>
          </li>
        </ul>
        <a
          href="/"
          aria-label="UJ"
          class="inline-flex items-center"
        >
          <img src={Logo} alt='Logo' className='w-auto lg:h-12 h-8' />
        </a>
        <ul class="flex items-center hidden space-x-14 lg:flex">
          <li>
            <a
              href="/projects"
              aria-label="Projects"
              class="tracking-wide text-black transition-colors duration-200 hover:underline uppercase font-bold"
            >
              Projects
            </a>
          </li>
          <li>
            <a
              href="/contact"
              aria-label="Contact"
              class="tracking-wide text-black transition-colors duration-200 hover:underline uppercase font-bold"
            >
              Contact
            </a>
          </li>
        </ul>
        <div class="lg:hidden">
          <button
            aria-label="Open Menu"
            class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
            onClick={() => setIsMenuOpen(true)}
          >
            <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
              />
              <path
                fill="currentColor"
                d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
              />
              <path
                fill="currentColor"
                d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
              />
            </svg>
          </button>
          {isMenuOpen && (
            <div class="absolute top-0 left-0 w-full">
              <div class="p-5 bg-white border rounded shadow-sm">
                <div class="flex items-center justify-between mb-4">
                  <div>
                    <a
                      href="/"
                      aria-label="UJ"
                      class="inline-flex items-center"
                    >
                      <img src={Logo} alt='Logo' className='w-auto h-8' />
                    </a>
                  </div>
                  <div>
                    <button
                      aria-label="Close Menu"
                      class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <nav>
                  <ul class="space-y-4">
                    <li>
                      <a
                        href="/"
                        aria-label="Home"
                        class="text-black transition-colors duration-200 hover:underline uppercase font-bold text-sm"
                      >
                        Home
                      </a>
                    </li>
                    <li>
                      <a
                        href="/about"
                        aria-label="About"
                        class="text-black transition-colors duration-200 hover:underline uppercase font-bold text-sm"
                      >
                        About
                      </a>
                    </li>
                    <li>
                      <a
                        href="/projects"
                        aria-label="Projects"
                        class="text-black transition-colors duration-200 hover:underline uppercase font-bold text-sm"
                      >
                        Projects
                      </a>
                    </li>
                    <li>
                      <a
                        href="/contact"
                        aria-label="Contact"
                        class="text-black transition-colors duration-200 hover:underline uppercase font-bold text-sm"
                      >
                        Contact
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
      </div>
    </main>
  )
}
